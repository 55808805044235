import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Link,
  useColorModeValue,
  Divider,
  HStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface ResumeItemProps {
  title: string;
  organization: string;
  period: string;
  description: React.ReactNode;
  achievements?: React.ReactNode[];
  tech?: string[];
  link?: string;
}

const ResumeItem: React.FC<ResumeItemProps> = ({
  title,
  organization,
  period,
  description,
  achievements,
  tech,
  link,
}) => {
  const titleColor = useColorModeValue("light.text", "dark.text");
  const textColor = useColorModeValue("light.secondary", "dark.secondary");
  const techColor = useColorModeValue("brand.accent", "brand.accent");

  return (
    <Box mb={8}>
      <Heading
        as="h3"
        fontSize={{ base: "xl", md: "2xl" }}
        mb={2}
        color={titleColor}
        fontWeight="500"
      >
        {link ? (
          <Link href={link} isExternal color="brand.accent">
            {title}
          </Link>
        ) : (
          title
        )}
      </Heading>
      <HStack spacing={2} mb={4} color={textColor}>
        <Text fontSize={{ base: "md", md: "lg" }}>{organization}</Text>
        <Text>•</Text>
        <Text fontSize={{ base: "md", md: "lg" }}>{period}</Text>
      </HStack>
      <Text fontSize={{ base: "md", md: "lg" }} color={textColor} mb={4}>
        {description}
      </Text>
      {achievements && (
        <UnorderedList spacing={2} mb={4} pl={4}>
          {achievements.map((achievement, index) => (
            <ListItem
              key={index}
              fontSize={{ base: "md", md: "lg" }}
              color={textColor}
            >
              {achievement}
            </ListItem>
          ))}
        </UnorderedList>
      )}
      {tech && (
        <HStack spacing={2} flexWrap="wrap">
          {tech.map((item) => (
            <Text key={item} fontSize="sm" color={techColor} fontFamily="mono">
              {item}
            </Text>
          ))}
        </HStack>
      )}
    </Box>
  );
};

const Resume: React.FC = () => {
  const headingColor = useColorModeValue("light.text", "dark.text");
  const linkColor = useColorModeValue("light.secondary", "dark.secondary");

  return (
    <Container maxW="4xl" py={{ base: 16, md: 20 }}>
      <VStack spacing={12} align="stretch">
        <Link
          as={RouterLink}
          to="/"
          fontSize={{ base: "lg", md: "xl" }}
          color={linkColor}
          _hover={{ color: "brand.accent" }}
          transition="all 0.2s ease-in-out"
        >
          ← Back home
        </Link>

        <Box>
          <Heading
            as="h1"
            fontSize={{ base: "4xl", md: "5xl" }}
            mb={12}
            color={headingColor}
          >
            Ben Hoffner-Brodsky
          </Heading>

          <ResumeItem
            title="Founder"
            organization="jhana.ai"
            period="2023-Present"
            description="Building an AI platform that makes legal knowledge accessible and actionable through intelligent search and analysis."
            achievements={[
              "Developed novel RAG architecture reducing hallucination rates by 76% compared to baseline",
              "Built custom vector embedding pipeline handling 100M+ legal documents",
              "Achieved 10x cost reduction in inference through optimized caching and chunking",
              "Live demo: jhana.ai/demo",
            ]}
            tech={[
              "Python",
              "PyTorch",
              "LangChain",
              "FastAPI",
              "TypeScript",
              "React",
              "PostgreSQL",
              "Redis",
            ]}
            link="https://link.jhana.ai/vc"
          />

          <Divider my={8} />

          <ResumeItem
            title="Research Consultant"
            organization="World Bank"
            period="2022-2023"
            description="Led development of ML systems for legal document analysis and policy recommendation."
            achievements={[
              "Built NLP pipeline processing 50K+ legal documents across 15 languages",
              "Developed citation graph analysis tool identifying key precedents",
              "Research paper: bit.ly/wb-legal-ml",
            ]}
            tech={[
              "Python",
              "spaCy",
              "Transformers",
              "NetworkX",
              "scikit-learn",
            ]}
          />

          <Divider my={8} />

          <ResumeItem
            title="Research Co-author"
            organization="University of Virginia"
            period="2022-2023"
            description="Applied ML to medical imaging for chronic kidney disease prediction."
            achievements={[
              "Developed CNN model achieving 83% accuracy in RV volume prediction",
              "Built automated pipeline for medical image preprocessing",
              "Published in Clinical Kidney Journal",
            ]}
            tech={["Python", "TensorFlow", "OpenCV", "pandas", "scikit-learn"]}
            link="https://academic.oup.com/ckj/article/16/9/1508/7136182"
          />

          <Divider my={8} />

          <ResumeItem
            title="Co-founder"
            organization="Aha! Academy"
            period="2020-2021"
            description="Built and scaled an edtech platform delivering personalized learning experiences."
            achievements={[
              "Developed adaptive learning system serving 10K+ students",
              "Implemented real-time analytics reducing student drop-off by 45%",
              "Scaled to $500K ARR within 9 months",
            ]}
            tech={["Python", "Django", "React", "PostgreSQL", "Redis", "AWS"]}
          />

          <Divider my={8} />

          <ResumeItem
            title="Research Assistant"
            organization="Harvard University"
            period="2019-2020"
            description="Developed Bayesian learning algorithms modeling social diffusion of agricultural practices."
            achievements={[
              "Built agent-based simulation framework for 100K+ nodes",
              "Implemented MCMC sampling for parameter estimation",
              "Live demo: bit.ly/social-learning-demo",
            ]}
            tech={["Python", "NumPy", "PyMC3", "NetworkX", "D3.js"]}
            link="https://github.com/ben-hb/social_learning"
          />
        </Box>
      </VStack>
    </Container>
  );
};

export default Resume;
