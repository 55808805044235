import React from "react";
import {
  Box,
  Container,
  Text,
  Link as ChakraLink,
  useColorModeValue,
} from "@chakra-ui/react";

interface WorkItemProps {
  role: string;
  company: string;
  period: string;
  location?: string;
  link?: string;
  news?: string;
  supervisor?: {
    name: string;
    link?: string;
  };
  github?: string;
  team?: {
    name: string;
    link?: string;
  };
}

const ExternalLink: React.FC<{ href: string }> = ({ href }) => {
  const subtleColor = useColorModeValue("gray.600", "gray.300");
  const linkHoverColor = useColorModeValue("black", "white");
  const linkStyles = {
    color: subtleColor,
    marginLeft: 1,
    transition: "color 0.2s",
  };

  return (
    <Box as="span" display="inline-block">
      <Box
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...linkStyles}
        sx={{
          "&:hover": {
            color: linkHoverColor,
          },
        }}
      >
        ↗
      </Box>
    </Box>
  );
};

const WorkItem: React.FC<WorkItemProps> = ({
  role,
  company,
  period,
  location,
  link,
  news,
  supervisor,
  github,
  team,
}) => {
  const textColor = useColorModeValue("gray.700", "gray.200");
  const subtleColor = useColorModeValue("gray.600", "gray.300");

  const Dot = () => (
    <Text as="span" color={subtleColor}>
      {" "}
      ·{" "}
    </Text>
  );

  const textStyles = {
    fontSize: "sm",
    fontWeight: "normal",
    letterSpacing: "wide",
  };

  const titleStyles = {
    ...textStyles,
    color: textColor,
    mb: 1,
  };

  const detailStyles = {
    ...textStyles,
    color: subtleColor,
  };

  return (
    <Box>
      <Box {...titleStyles}>
        {role}
        <Dot />
        <Text as="span" color={subtleColor}>
          {company}
        </Text>
        {link && <ExternalLink href={link} />}
      </Box>
      <Box {...detailStyles}>
        {period}
        {location && (
          <>
            <Dot />
            {location}
          </>
        )}
        {team && (
          <>
            <Dot />
            {team.name}
            {team.link && <ExternalLink href={team.link} />}
          </>
        )}
        {supervisor && (
          <>
            <Dot />
            {supervisor.name}
            {supervisor.link && <ExternalLink href={supervisor.link} />}
          </>
        )}
        {github && (
          <>
            <Dot />
            code
            <ExternalLink href={github} />
          </>
        )}
        {news && (
          <>
            <Dot />
            press
            <ExternalLink href={news} />
          </>
        )}
      </Box>
    </Box>
  );
};

const Work: React.FC = () => {
  return (
    <Box py={28}>
      <Container maxW="xl">
        <Box display="flex" flexDirection="column" gap={12}>
          <WorkItem
            role="co-founder"
            company="jhana"
            period="sep 2022 - dec 2024"
            link="https://jhana.ai"
            news="https://inc42.com/buzz/jhana-ai-bags-funding-to-build-an-ai-powered-research-drafting-tool-for-lawyers/"
          />

          <WorkItem
            role="z fellow"
            company="z fellows"
            period="apr 2023"
            link="https://www.zfellows.com"
          />

          <WorkItem
            role="emergent ventures grantee"
            company="mercatus center"
            period="may 2022"
            location="george mason university"
          />

          <WorkItem
            role="research consultant"
            company="world bank"
            period="feb 2021 - apr 2022"
            location="washington, dc"
            supervisor={{
              name: "daniel chen",
              link: "https://users.nber.org/~dlchen/",
            }}
          />

          <WorkItem
            role="research analyst"
            company="u.s. department of the treasury"
            period="dec 2020 - may 2021"
            location="washington, dc"
            link="https://home.treasury.gov/about/offices/international-affairs"
            team={{
              name: "south and southeast asia",
            }}
          />

          <WorkItem
            role="international trade analyst"
            company="u.s. department of commerce"
            period="aug 2020 - dec 2020"
            location="boston, ma"
            link="https://www.trade.gov/"
          />

          <WorkItem
            role="research assistant"
            company="harvard business school"
            period="jan 2020 - aug 2020"
            location="cambridge, ma"
            link="https://www.hbs.edu/"
            supervisor={{
              name: "matthew rabin",
              link: "https://scholar.harvard.edu/rabin/home",
            }}
            github="https://github.com/ben-hb/social_learning"
          />

          <WorkItem
            role="data science + policy analyst"
            company="j-pal"
            period="may 2019 - aug 2019"
            location="chennai, india"
            team={{
              name: "idea lab",
              link: "https://www.povertyactionlab.org/initiative/innovations-data-and-experiments-action-initiative",
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default Work;
