import React from "react";
import {
  Box,
  useColorMode,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsSun, BsMoon } from "react-icons/bs";
import { Routes, Route } from "react-router-dom";
import Hero from "./components/Hero";
import Work from "./components/Work";
import Resume from "./components/Resume";

const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const color = useColorModeValue("gray.500", "gray.400");
  const hoverColor = useColorModeValue("gray.800", "gray.100");

  return (
    <IconButton
      aria-label="Toggle theme"
      icon={colorMode === "light" ? <BsMoon size={16} /> : <BsSun size={16} />}
      variant="ghost"
      size="sm"
      color={color}
      onClick={toggleColorMode}
      _hover={{
        color: hoverColor,
        bg: "transparent",
      }}
    />
  );
};

const HomePage = () => (
  <>
    <Box id="hero">
      <Hero />
    </Box>
    <Box id="work">
      <Work />
    </Box>
  </>
);

function App() {
  return (
    <Box as="main" minH="100vh" position="relative" overflow="hidden">
      <Box position="fixed" top={4} right={4} zIndex={100}>
        <ThemeToggle />
      </Box>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Box>
  );
}

export default App;
