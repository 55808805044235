import { extendTheme, type ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

const colors = {
  brand: {
    accent: "#D4A373", // Warm terracotta accent
  },
  light: {
    bg: "#FEFCF8", // Warm white
    text: "#2D2D2D", // Dark gray
    secondary: "#6B6B6B", // Medium gray
    surface: "#F5F2ED", // Soft beige
  },
  dark: {
    bg: "#1A1A1A", // Soft black
    text: "#FFFFFF", // Pure white for better contrast
    secondary: "#E0E0E0", // Light gray for better readability
    surface: "#2D2D2D", // Deep warm gray
  },
};

const fonts = {
  heading: "Spectral, serif",
  body: "Inter, system-ui, sans-serif",
};

const components = {
  IconButton: {
    variants: {
      ghost: {
        _hover: {
          bg: "transparent",
          color: "brand.accent",
          transform: "scale(1.1)",
        },
        _active: {
          transform: "scale(0.95)",
        },
        transition: "all 0.2s ease-in-out",
      },
    },
  },
  Link: {
    baseStyle: {
      _hover: {
        transform: "scale(1.1)",
        textDecoration: "none",
      },
      _active: {
        transform: "scale(0.95)",
      },
      transition: "all 0.2s ease-in-out",
    },
  },
};

const styles = {
  global: {
    body: {
      bg: "light.bg",
      color: "light.text",
      _dark: {
        bg: "dark.bg",
        color: "dark.text",
      },
    },
  },
};

const theme = extendTheme({
  config,
  colors,
  fonts,
  components,
  styles,
});

export default theme;
