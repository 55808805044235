import React from "react";
import {
  Box,
  Container,
  Text,
  Link as ChakraLink,
  HStack,
  VStack,
  Heading,
  useColorModeValue,
  SystemStyleObject,
  BoxProps,
} from "@chakra-ui/react";
import { FaGithub, FaLinkedin } from "react-icons/fa";

const SocialIcon: React.FC<{
  href: string;
  color: string;
  hoverColor: string;
  icon: React.ReactNode;
}> = ({ href, color, hoverColor, icon }) => (
  <Box as="span" display="inline-block">
    <ChakraLink
      href={href}
      display="inline-flex"
      alignItems="center"
      color={color}
      transition="all 0.2s"
      _hover={{
        color: hoverColor,
        transform: "translateY(-1px)",
      }}
    >
      {icon}
    </ChakraLink>
  </Box>
);

const SocialLinks: React.FC<{ color: string; hoverColor: string }> = ({
  color,
  hoverColor,
}) => (
  <Box mb={4}>
    <HStack spacing={5}>
      <SocialIcon
        href="https://github.com/ben-hb"
        color={color}
        hoverColor={hoverColor}
        icon={<FaGithub size={18} />}
      />
      <SocialIcon
        href="https://www.linkedin.com/in/benhoffnerbrodsky/"
        color={color}
        hoverColor={hoverColor}
        icon={<FaLinkedin size={18} />}
      />
    </HStack>
  </Box>
);

const StyledLink: React.FC<{
  href: string;
  color: string;
  hoverColor: string;
  children: React.ReactNode;
}> = ({ href, color, hoverColor, children }) => (
  <Box as="span" display="inline-block">
    <ChakraLink
      href={href}
      color={color}
      transition="all 0.2s"
      fontSize="sm"
      fontWeight="normal"
      letterSpacing="wide"
      position="relative"
      _after={{
        content: '""',
        position: "absolute",
        bottom: "-1px",
        left: 0,
        width: "100%",
        height: "1px",
        backgroundColor: "currentColor",
        opacity: 0.4,
        transition: "opacity 0.2s",
      }}
      _hover={{
        color: hoverColor,
        textDecoration: "none",
        _after: {
          opacity: 1,
        },
      }}
    >
      {children}
    </ChakraLink>
  </Box>
);

const EmailLink: React.FC<{ color: string; hoverColor: string }> = ({
  color,
  hoverColor,
}) => {
  const emailParts = {
    user: "benhb",
    at: " at ",
    domain: "hey.com",
  };

  return (
    <Box display="inline-block">
      <ChakraLink
        href={`mailto:${emailParts.user}@${emailParts.domain}`}
        color={color}
        transition="all 0.2s"
        fontSize="sm"
        fontWeight="normal"
        letterSpacing="wide"
        _hover={{
          color: hoverColor,
          textDecoration: "none",
          transform: "translateY(-1px)",
        }}
      >
        {emailParts.user}
        <Text as="span" fontStyle="italic" opacity={0.8}>
          {emailParts.at}
        </Text>
        {emailParts.domain}
      </ChakraLink>
    </Box>
  );
};

const Hero: React.FC = () => {
  const textColor = useColorModeValue("gray.700", "gray.200");
  const subtleColor = useColorModeValue("gray.600", "gray.300");
  const linkHoverColor = useColorModeValue("black", "white");

  return (
    <Box as="main" py={28}>
      <Container maxW="xl">
        <Heading
          as="h1"
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight="light"
          letterSpacing="wide"
          color={textColor}
          mb={8}
          textTransform="lowercase"
        >
          <Text as="span" display="block">
            ben hoffner-brodsky
          </Text>
        </Heading>
        <Box as="section" aria-label="Professional Summary">
          <Text
            fontSize="sm"
            fontWeight="normal"
            letterSpacing="wide"
            color={textColor}
            mb={8}
            lineHeight="relaxed"
          ></Text>
        </Box>
        <Box as="section" aria-label="Contact Information">
          <SocialLinks color={subtleColor} hoverColor={linkHoverColor} />
          <EmailLink color={subtleColor} hoverColor={linkHoverColor} />
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
